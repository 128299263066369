






























































import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import { changeRoute, isInApp, sendToApp } from '@/helpers'
import { useI18n } from 'vue-composable'
import axios from 'axios'
import router, { loginByToken } from '@/router'
import phoneCodes from '@/data/phone_code'
import { v4 as uuidv4 } from 'uuid'

type PhoneCode = {
  'iso': string,
  'phone_code': string,
  'name': string,
  'country_id': number
}

interface State {
  [key: string]: any,
  phoneCodes: PhoneCode[]
}

const slideItems = [
  {
    src: require('@/assets/images/thumb/login-01.png'),
    text: 'Benefit.label.BeforeLogin.NoFee'
  },
  {
    src: require('@/assets/images/thumb/login-02.png'),
    text: 'Benefit.label.BeforeLogin.Coupons'
  },
  {
    src: require('@/assets/images/thumb/login-03.png'),
    text: 'Benefit.label.BeforeLogin.VipCredits'
  },
  {
    src: require('@/assets/images/thumb/login-04.png'),
    text: 'Benefit.label.BeforeLogin.Promotions'
  }
]

const setBodyClass = () => {
  document.body.classList.add(router.app.$route.name as string)
}

export default defineComponent({
  setup () {
    const { $t } = useI18n()

    const state = reactive({
      $t,
      toCountryId: (code = '') => {
        return state.phoneCodes.find(item => item.phone_code === code)?.country_id
      },
      form: {
        code: '',
        user_id: '',
        password: ''
      },
      isDev: process.env.NODE_ENV !== 'production',
      isInApp,
      login: async () => {
        const uuid = sessionStorage.uuid || uuidv4()
        const payload = {
          ...state.form,
          ...(state.form.code && {
            phone_country_id: state.toCountryId(state.form.code)
          })
        }
        delete payload.code
        const { data } = await axios.post('/api/signin', payload, {
          headers: {
            api_version: '3.1',
            device_trace_id: uuid
          }
        })
        sessionStorage.uuid = uuid
        const { token: { auth: jwt, refresh } } = data
        loginByToken({ query: { jwt, refresh_jwt: refresh, uuid } })
        changeRoute({ baseUri: location.origin, path: '/benefits' })
        document.body.classList.remove(router.app.$route.name as string)
      },
      phoneCodes,
      slideItems,
      slide: 0,
      sendToApp
    }) as State

    setBodyClass()

    return {
      ...toRefs(state)
    }
  }
})
